@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  font-family: 'Cairo';
}

.no-tailwindcss {
  all: unset;
}